import React from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { getPassengerFormData } from "./selectors";

export const dateOfBirthInvalid = () => {
  const passengerFormData = useSelector(getPassengerFormData);

  return (
    !!passengerFormData?.passenger?.dob &&
    DateTime.fromISO(passengerFormData.passenger.dob) > DateTime.now()
  );
};

const DateOfBirthValidationMessage = () => {
  const isInvalid = dateOfBirthInvalid();

  return isInvalid ? (
    <p className="step-validation-message">Date of Birth must be in the past</p>
  ) : null;
};

export default DateOfBirthValidationMessage;
