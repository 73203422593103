import React from "react";
import { Form } from "@redriver/cinnamon";
import { ColumnGrid } from "components/containers";
import { YesNoRadioField } from "components/forms";
import TabLockedMessage from "./TabLockedMessage";
import { useSelector } from "react-redux";
import { getCurrentStepLocked } from "./selectors";

const MedicalForm = () => {
  const isLocked = useSelector(getCurrentStepLocked);
  return (
    <ColumnGrid cols={2}>
      <ColumnGrid.Item>
        <YesNoRadioField
          field="hasMedicalRequirements"
          label="Does the passenger have medical/dietary requirements?"
          required
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      <Form.If
        condition={({ fields }) => fields.hasMedicalRequirements == true}
      >
        <ColumnGrid.Item>
          <Form.Input
            field="dietaryRequirements"
            label={
              <label>
                Dietary Requirements <br />
                If you have no dietary requirements, please leave blank
              </label>
            }
            fluid
            disabled={isLocked}
          />
        </ColumnGrid.Item>
        <ColumnGrid.Item>
          <YesNoRadioField
            field="epipenCarrier"
            label="Epipen Carrier?"
            required
            disabled={isLocked}
          />
        </ColumnGrid.Item>
        <ColumnGrid.Item>
          <Form.Input
            field="medicalRequirements"
            label={
              <label>
                Medical Requirements <br />
                If you have no medical requirements, please leave blank
              </label>
            }
            fluid
            disabled={isLocked}
          />
        </ColumnGrid.Item>
      </Form.If>
      {isLocked && (
        <ColumnGrid.Item colsWidth={2}>
          <TabLockedMessage />
        </ColumnGrid.Item>
      )}
    </ColumnGrid>
  );
};

export default MedicalForm;
