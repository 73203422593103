import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setOfflineStatus } from "./actions";

const NetworkChecker = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const online = () => dispatch(setOfflineStatus(false));
    const offline = () => dispatch(setOfflineStatus(true));

    window.addEventListener("offline", offline);
    window.addEventListener("online", online);

    return () => {
      window.removeEventListener("offline", offline);
      window.removeEventListener("online", online);
    };
  }, []);

  return null;
};

export default NetworkChecker;
