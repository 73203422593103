import React from "react";
import { Form, ActionBar, ActionBarItem } from "@redriver/cinnamon";
import { moveStep, saveStep } from "./actions";
import {
  getCompletedStepIndex,
  getIsConfirmed,
  getIsSubmitted,
} from "./selectors";
import { useDispatch, useSelector } from "react-redux";
import { Heading } from "components/containers";
import { RoundedButton } from "components/buttons";
import FormPanel from "./FormPanel";
import SubmitButton from "./SubmitButton";
import SubmitDetails from "./SubmitDetails";
import SkiForm from "./SkiForm";
import MedicalForm from "./MedicalForm";
import PassportForm from "./PassportForm";
import PassengerForm from "./PassengerForm";
import { PassengerSteps } from "constants/enums";

const buildForm = (stepKey) => {
  switch (stepKey) {
    case PassengerSteps.Passenger:
      return <PassengerForm />;
    case PassengerSteps.Passport:
      return <PassportForm />;
    case PassengerSteps.Medical:
      return <MedicalForm />;
    case PassengerSteps.Ski:
      return <SkiForm />;
    case PassengerSteps.Submit:
      return <SubmitDetails />;
    default:
      return null;
  }
};

const FormStep = ({
  stepIndex,
  step,
  hasNextStep,
  hasPreviousStep,
  onNextStep,
  onPreviousStep,
  steps,
  ...props
}) => {
  const dispatch = useDispatch();
  const form = buildForm(step.key);
  const isConfirmed = useSelector(getIsConfirmed);
  const stepCompletedIndex = useSelector(getCompletedStepIndex);

  return (
    <div className="form-step">
      <Form.Object field={step.key}>
        <div className="form-step-heading">
          <Heading>{step.name}</Heading>
          {step.description && <p>{step.description}</p>}
        </div>
        <FormPanel>
          {form}

          <ActionBar className="form-nav">
            {hasPreviousStep && (
              <ActionBar.Item>
                <RoundedButton
                  onClick={() => {
                    if (!isConfirmed) {
                      dispatch(
                        saveStep({
                          stepKey: step.key,
                          completedStepIndex: stepCompletedIndex,
                        })
                      );
                    }
                    dispatch(
                      moveStep({
                        targetStepKey: steps[stepIndex - 1].key,
                      })
                    );
                  }}
                  primary
                >
                  Back
                </RoundedButton>
              </ActionBar.Item>
            )}
            <ActionBar.Space />
            {hasNextStep && (
              <ActionBar.Item>
                <RoundedButton
                  onClick={() => {
                    if (!isConfirmed) {
                      const nextCompletedIndex =
                        stepCompletedIndex == null ||
                        stepCompletedIndex < stepIndex
                          ? stepIndex
                          : stepCompletedIndex;

                      dispatch(
                        saveStep({
                          stepKey: step.key,
                          completedStepIndex: nextCompletedIndex,
                        })
                      );
                    }
                    dispatch(
                      moveStep({
                        targetStepKey: steps[stepIndex + 1].key,
                      })
                    );
                  }}
                  positive
                >
                  Next
                </RoundedButton>
              </ActionBar.Item>
            )}
            {!hasNextStep && !isConfirmed && (
              <React.Fragment>
                <SubmitButton confirm={false} />
                <SubmitButton confirm />
              </React.Fragment>
            )}
          </ActionBar>
        </FormPanel>
      </Form.Object>
    </div>
  );
};

export default FormStep;
