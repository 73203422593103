import React from "react";
import { ScreenCheck, useLoggedIn } from "@redriver/cinnamon";
//import SimpleMountains from "assets/simple-mountains.svg";

const BackgroundContent = () => {
  return (
    <div className="login-img">
      {/* <ScreenCheck size="small">
        <img
          src={SimpleMountains}
          className="mobile-mountains"
          style={{ display: "block", width: "100%" }}
        />
      </ScreenCheck> */}
    </div>
  );
};

export default BackgroundContent;
