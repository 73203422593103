import React from "react";
import {
  requestResponse,
  requestIsSuccess,
  requestIsBusy,
} from "@redriver/cinnamon";
import { PassengerSteps, PassengerTabs, TripTypes } from "constants/enums";
import { ActionTypes } from "./actions";

const RequiredFields = ({ additionalText }) => (
  <>
    Fields marked with a <span className="red">*</span> must be completed
    {additionalText}
  </>
);

const buildSteps = (tripType, isUkTrip, lockedPassengerTabs) => {
  const steps = [
    {
      key: PassengerSteps.Passenger,
      name: "Passenger",
      title: "Passenger Details",
      description: (
        <>
          Please enter all of the travelling passenger's information accurately,
          as it appears on their passport, including any middle names (if
          travelling Long Haul)
          <br />
          <RequiredFields />
        </>
      ),
      locked: (lockedPassengerTabs || []).includes(PassengerTabs.KeyInfo),
    },
  ];

  if (!isUkTrip) {
    steps.push({
      key: PassengerSteps.Passport,
      name: "Passport",
      title: "Passport Details",
      description: (
        <>
          Please enter all of the passenger's information accurately, as it
          appears on the passport. <RequiredFields />
          <br />
          IMPORTANT NOTE: Please ensure you check the UK government’s foreign
          travel advice for your destination (and transit countries){" "}
          <a href="https://www.gov.uk/foreign-travel-advice" target="_blank">
            https://www.gov.uk/foreign-travel-advice
          </a>
          . Passengers are responsible for ensuring that they meet all Entry
          Requirements. Select cannot accept responsibility for any issues
          arising from individuals not adhering to entry (or vaccination)
          regulations, nor are these insurable.
        </>
      ),
      locked: (lockedPassengerTabs || []).includes(PassengerTabs.Apis),
    });
  }

  steps.push({
    key: PassengerSteps.Medical,
    name: "Medical and Dietary",
    title: "Medical and Dietary Information",
    description: (
      <>
        Please enter all of the passenger's information accurately
        <br />
        <RequiredFields />
      </>
    ),
    locked: (lockedPassengerTabs || []).includes(PassengerTabs.Diet),
  });

  if (tripType == TripTypes.Ski) {
    steps.push({
      key: PassengerSteps.Ski,
      name: "Ski",
      title: "Ski Information",
      description: (
        <>
          Please enter all of the passenger's information accurately
          <br />
          We know that children can grow quickly, so skier height, weight, head
          circumference and shoe size should only be completed 3-4 months prior
          to date of travel.
          <br />
          <RequiredFields additionalText=" straight away but all fields are required within 3-4 months prior to departure." />
        </>
      ),
      locked: (lockedPassengerTabs || []).includes(PassengerTabs.Ski),
    });
  }
  steps.push({
    key: PassengerSteps.Submit,
    name: "Save & Confirm",
    title: "Save & Confirm",
    description: null,
  });
  return steps;
};

const initialState = {
  formData: {},
  loading: false,
  tripType: null,
  isUkTrip: false,
  noSnowboarders: false,
  passengerType: null,
  completedStepIndex: null,
  currentStepKey: null,
  steps: buildSteps(),
  isSubmitted: false,
  isConfirmed: false,
  submitting: false,
  saving: false,
  isValid: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetPassengerInfo: {
      const isOk = requestIsSuccess(action);
      const response = requestResponse(action);
      const loading = requestIsBusy(action);

      const steps = isOk
        ? buildSteps(
            response.tripType,
            response.isUkTrip,
            response.lockedPassengerTabs
          )
        : state.steps;
      const completedIndex = response?.completedStepIndex;
      const nextStepKey =
        isOk && response?.completedStepIndex != null
          ? completedIndex >= 0 && completedIndex < steps.length - 1
            ? steps[completedIndex + 1]?.key
            : null
          : state.currentStepKey;

      return {
        ...state,
        loading,
        formData: isOk
          ? {
              ...response.data,
              [PassengerSteps.Submit]: { agree: response.isConfirmed },
            }
          : state.formData,
        tripType: isOk ? response.tripType : state.tripType,
        isUkTrip: isOk ? response.isUkTrip : state.isUkTrip,
        noSnowboarders: isOk ? response.noSnowboarders : state.noSnowboarders,
        passengerType: isOk ? response.passengerType : state.passengerType,
        steps: steps,
        returnDate: isOk ? response.returnDate : state.returnDate,

        completedStepIndex: isOk
          ? response.isSubmitted
            ? steps.length - 1
            : response.completedStepIndex
          : state.completedStepIndex,

        currentStepKey: isOk ? nextStepKey || steps[0].key : steps[0].key,
        isSubmitted: isOk ? response.isSubmitted : state.isSubmitted,
        isConfirmed: isOk ? response.isConfirmed : state.isConfirmed,
      };
    }

    case ActionTypes.MoveStep: {
      const targetIndex = state.steps.findIndex(
        (x) => x.key == action.targetStepKey
      );
      const currentCompleteIndex =
        state.completedStepIndex == null ? -1 : state.completedStepIndex;

      return {
        ...state,
        currentStepKey: action.targetStepKey,
        completedStepIndex:
          currentCompleteIndex < targetIndex
            ? targetIndex - 1
            : state.completedStepIndex,
      };
    }

    case ActionTypes.UpdateForm: {
      return {
        ...state,
        formData: action.applyChanges(state.formData),
      };
    }

    case ActionTypes.Submit: {
      const isConfirm = action.customData.confirm;
      const submitting = requestIsBusy(action) && isConfirm;
      const saving = requestIsBusy(action) && !isConfirm;

      const finishedSubmitting =
        (state.submitting && !submitting) || (state.saving && !saving);
      return {
        ...state,
        submitting,
        saving,
        isSubmitted: finishedSubmitting ? true : state.isSubmitted,
        isConfirmed: finishedSubmitting && isConfirm ? true : state.isConfirmed,
        completedStepIndex: finishedSubmitting
          ? state.steps.length - 1
          : state.completedStepIndex,
      };
    }

    case ActionTypes.SetValidation: {
      return {
        ...state,
        isValid: !!action.isValid,
      };
    }

    default:
      return state;
  }
};
