import { Form, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { requestCancellation } from "./actions";
import { Button } from "semantic-ui-react";
import { ParentModal } from "components/containers/modals";

const RequestCancellation = ({
  as: As = Button,
  onSubmitted = () => {},
  ...props
}) => (
  <ModalBuilder
    withForm
    submitAction={requestCancellation}
    onSubmitted={onSubmitted}
    renderTrigger={(openModal) => <As onClick={openModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <ParentModal {...modalProps} header="Request Cancellation" size="small">
        <Form {...formProps}>
          <p style={{ marginTop: 0 }}>
            Please note: requests for cancelling of passenger from trip can only
            be submitted and you will be contacted to confirm the request. There
            may be penalty charges depending on the departure date of the trip.
          </p>
          <Form.Group widths={2}>
            <Form.Input field="phone" label="Phone" fluid />
          </Form.Group>
          <Form.TextArea
            field="reason"
            label="Reason for Cancellation"
            fluid
            required
          />
        </Form>
      </ParentModal>
    )}
  />
);

export default RequestCancellation;
