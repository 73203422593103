import React from "react";
import { PageWrapper } from "components/pages";
import { EditChildDetails } from "features/Parent/EditChildDetails";

const ChildDetailsPage = () => {
  return (
    <PageWrapper>
      <EditChildDetails />
    </PageWrapper>
  );
};

export default ChildDetailsPage;
