import React from "react";
import { useLookup } from "@redriver/cinnamon";
import { useSelector } from "react-redux";
import moment from "moment";
import { getPassengerFormData, getTripType, getIsUkTrip } from "./selectors";
import { Message } from "semantic-ui-react";
import {
  genderLookup,
  countriesLookup,
  skierTypesLookup,
  skiAbilitiesLookup,
  ownSkiEquipmentLookup,
} from "./lookups";
import { TripTypes } from "constants/enums";

const PassengerSummaryMessage = () => {
  const formData = useSelector(getPassengerFormData);
  const tripType = useSelector(getTripType);
  const isUkTrip = useSelector(getIsUkTrip);

  const [{ response: genderResponse }] = useLookup(genderLookup);
  const [{ response: countriesResponse }] = useLookup(countriesLookup);
  const [{ response: skierTypesResponse }] = useLookup(skierTypesLookup);
  const [{ response: skiAbilitiesResponse }] = useLookup(skiAbilitiesLookup);
  const [{ response: ownSkiEquipmentResponse }] = useLookup(
    ownSkiEquipmentLookup
  );

  const summaryItems = [
    {
      name: "Passenger",
      items: [
        {
          name: "Title",
          value: formData.passenger.title,
        },
        {
          name: "First Name",
          value: formData.passenger.firstName,
        },
        {
          name: "Middle Name",
          value: formData.passenger.middleName,
        },
        {
          name: "Last Name",
          value: formData.passenger.lastName,
        },
        {
          name: "Gender",
          value: (genderResponse || []).find(
            (x) => x.value == formData.passenger.gender
          )?.text,
        },
      ],
    },
    {
      name: "Passport",
      hidden: isUkTrip,
      items: [
        {
          name: "Passport Number",
          value: formData.passport.passportNum,
        },
        {
          name: "Passport Date of Issue",
          value: moment(formData.passport.passportDateOfIssue).format(
            "DD/MM/YYYY"
          ),
        },
        {
          name: "Passport Expiry Date",
          value: moment(formData.passport.passportExpiryDate).format(
            "DD/MM/YYYY"
          ),
        },
        {
          name: "Nationality",
          value: (countriesResponse || []).find(
            (x) => x.value == formData.passport.nationality
          )?.text,
        },
        {
          name: "Country of Issue/Code",
          value: () => {
            const country = (countriesResponse || []).find(
              (x) => x.value == formData.passport.issueCountry
            );
            if (!country) return null;
            return country.text + " (" + country.code + ")";
          },
        },
      ],
    },
    {
      name: "Medical and Dietary",
      items: [
        {
          name: "Does the passenger have medical/dietary requirements?",
          value: formData.medical.hasMedicalRequirements ? "Yes" : "No",
        },
        {
          name: "Dietary Requirements",
          value: formData.medical.dietaryRequirements,
          hidden: !formData.medical.hasMedicalRequirements,
        },
        {
          name: "Epipen Carrier",
          value: formData.medical.epipenCarrier ? "Yes" : "No",
          hidden: !formData.medical.hasMedicalRequirements,
        },
        {
          name: "Medical Requirements",
          value: formData.medical.medicalRequirements,
          hidden: !formData.medical.hasMedicalRequirements,
        },
      ],
    },
    {
      name: "Ski",
      hidden: tripType != TripTypes.Ski,
      items: [
        {
          name: "Skier/Boarder",
          value: (skierTypesResponse || []).find(
            (x) => x.value == formData.ski?.skierType
          )?.text,
        },
        {
          name: "Ability",
          value: (skiAbilitiesResponse || []).find(
            (x) => x.value == formData.ski?.skiAbility
          )?.text,
        },
        {
          name: "Own Equipment",
          value: (ownSkiEquipmentResponse || []).find(
            (x) => x.value == formData.ski?.ownEquipment
          )?.text,
        },
        {
          name: "Height (cm)",
          value: formData.ski?.height,
        },
        {
          name: "Weight (kg)",
          value: formData.ski?.weight,
        },
        {
          name: "UK Shoe Size",
          value: formData.ski?.shoeSize,
        },
        {
          name: "Head Circumference (cm)",
          value: formData.ski?.headSize,
        },
      ],
    },
  ];

  return (
    <Message positive>
      <Message.Header>Summary of Submitted Information</Message.Header>
      <Message.Content>
        {summaryItems
          .filter((x) => x.hidden != true)
          .map((s, i) => {
            return (
              <p key={i}>
                <b>{s.name}</b>
                <br />
                {s.items
                  .filter((x) => x.hidden != true)
                  .map((x, i) => {
                    return (
                      <span key={i} style={{ display: "block" }}>{`${x.name}: ${
                        (typeof x.value == "function" ? x.value() : x.value) ||
                        ""
                      }`}</span>
                    );
                  })}
              </p>
            );
          })}
      </Message.Content>
    </Message>
  );
};

export default PassengerSummaryMessage;
