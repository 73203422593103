import classNames from "classnames";
import React from "react";

const Heading = ({ className, children, ...props }) => {
  return (
    <h2 className={classNames("header-text", className)} {...props}>
      {children}
    </h2>
  );
};

export default Heading;
