import React from "react";
import { useIsOffline } from "components/NetworkChecker";
import { Heading } from "components/containers";
import { Segment, Dimmer } from "semantic-ui-react";

const OfflineNotice = () => {
  const isOffline = useIsOffline();
  if (isOffline) {
    return (
      <Dimmer.Dimmable as={"div"} dimmed className="offline">
        <Dimmer active verticalAlign="top">
          <Segment className="offline-panel">
            <Heading>You are currently offline</Heading>
            <p>You must be online in order to use this application</p>
          </Segment>
        </Dimmer>
      </Dimmer.Dimmable>
    );
  }
  return null;
};

export default OfflineNotice;
