import { apiGet, apiPost, apiPut, mockSuccess } from "@redriver/cinnamon";
import {
  getCompletedStepIndex,
  getIsConfirmed,
  getIsSubmitted,
  getPassengerFormData,
  getSteps,
} from "./selectors";

const NAMESPACE = "PARENT/CHILD_DETAILS";

export const ActionTypes = {
  GetPassengerInfo: `${NAMESPACE}/GET_PASSENGER_INFO`,
  MoveStep: `${NAMESPACE}/MOVE_STEP`,
  UpdateForm: `${NAMESPACE}/UPDATE_FORM`,
  SaveStep: `${NAMESPACE}/SAVE_STEP`,
  Submit: `${NAMESPACE}/SUBMIT`,
  GetCountries: `${NAMESPACE}/GET_COUNTRIES`,
  GetSkiAbilities: `${NAMESPACE}/GET_SKI_ABILITIES`,
  GetGenderOptions: `${NAMESPACE}/GET_GENDER_OPTIONS`,
  GetSkierTypes: `${NAMESPACE}/GET_SKIER_TYPES`,
  GetOwnSkiEquipmentTypes: `${NAMESPACE}/GET_OWN_SKI_EQUIPMENT_TYPES`,
  GetTitles: `${NAMESPACE}/GET_TITLES`,
  SetValidation: `${NAMESPACE}/SET_VALIDATION`,
};

export const getPassenger = () => {
  return apiGet(
    ActionTypes.GetPassengerInfo,
    `passenger-portal/PassengerAnswerSet`
  );
};

export const moveStep = ({ targetStepKey }) => ({
  type: ActionTypes.MoveStep,
  targetStepKey,
});

export const submitDetails = (confirm, manuallySaved = false) => {
  return apiPost(
    ActionTypes.Submit,
    `passenger-portal/SubmitPassengerAnswerSet`,
    {
      confirm: !!confirm,
      manuallySaved: !!manuallySaved,
    },
    { actionCustomData: { confirm: !!confirm } }
  );
};

export const updateForm = ({ applyChanges }) => ({
  type: ActionTypes.UpdateForm,
  applyChanges,
});

export const saveStep =
  ({ stepKey, completedStepIndex }) =>
  (dispatch, getState) => {
    const state = getState();
    const formState = getPassengerFormData(state);
    const request = {
      data: {
        [stepKey]: formState[stepKey],
      },
      completedStepIndex,
    };

    const isSubmitted = getIsSubmitted(state);

    dispatch(
      apiPut(
        ActionTypes.SaveStep,
        `passenger-portal/PassengerAnswerSet`,
        request
      )
    ).then(() => {
      // re-submit the details if the details have previously been submitted
      if (isSubmitted) {
        dispatch(submitDetails());
      }
    });
  };

export const getCountries = () =>
  apiGet(ActionTypes.GetCountries, `passenger-portal/Countries`);

export const getSkiAbilities = () =>
  apiGet(ActionTypes.GetSkiAbilities, `passenger-portal/SkiAbilities`);

export const getGenderOptions = () =>
  apiGet(ActionTypes.GetGenderOptions, `passenger-portal/Gender`);

export const getSkier = () =>
  apiGet(ActionTypes.GetSkierTypes, `passenger-portal/SkierTypes`);

export const getOwnSkiEquipment = () =>
  apiGet(
    ActionTypes.GetOwnSkiEquipmentTypes,
    `passenger-portal/OwnSkiEquipment`
  );

export const getTitles = () =>
  apiGet(ActionTypes.GetTitles, `passenger-portal/Titles`);

export const setValidation = (isValid) => ({
  type: ActionTypes.SetValidation,
  isValid,
});
