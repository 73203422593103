import React, { useEffect } from "react";
import { Form } from "@redriver/cinnamon";
import { useDispatch, useSelector } from "react-redux";
import { getIsSubmitted, getIsConfirmed } from "./selectors";
import { setValidation } from "./actions";
import { Message } from "semantic-ui-react";
import usePassengerValidation from "./usePassengerValidation";
import PassportValidationMessage, {
  passportDateInvalid,
  passportExpiresCloseToReturn,
} from "./PassportValidationMessage";
import usePassengerSubmitValidation from "./usePassengerSubmitValidation";
import DateOfBirthValidationMessage, {
  dateOfBirthInvalid,
} from "./DateOfBirthValidationMessage";
import PassengerSummaryMessage from "./PassengerSummaryMessage";

const SubmitDetails = () => {
  const isSubmitted = useSelector(getIsSubmitted);
  const isConfirmed = useSelector(getIsConfirmed);
  const dispatch = useDispatch();
  const validationResult = usePassengerValidation();
  const submitValidationResult = usePassengerSubmitValidation(null);

  useEffect(() => {
    dispatch(
      setValidation(!validationResult.pending && validationResult.valid)
    );
  }, [validationResult]);

  const submitValidationInvalid =
    !submitValidationResult.pending && !submitValidationResult.valid;

  const displayValdationErrors =
    passportDateInvalid() ||
    passportExpiresCloseToReturn() ||
    dateOfBirthInvalid();

  return (
    <div className="submit-details">
      {isConfirmed ? (
        <p>
          <strong>
            Thank you for confirming these details. Any changes you wish to make
            can only be done by request.
          </strong>
        </p>
      ) : (
        <React.Fragment>
          {!validationResult.pending && !validationResult.valid && (
            <Message negative>
              <Message.Header>Missing Information</Message.Header>
              <Message.Content>
                The following fields must be completed before confirming:
                <ul style={{ margin: 0 }}>
                  {validationResult.errors.map((x, i) => (
                    <li key={i}>{x}</li>
                  ))}
                </ul>
              </Message.Content>
            </Message>
          )}

          <PassportValidationMessage />
          <DateOfBirthValidationMessage />

          {!validationResult.pending && validationResult.valid ? (
            <React.Fragment>
              <p style={{ marginTop: "1em" }}>
                <strong>
                  Please ensure that you have completed all required information
                  correctly. Once confirmed these details can only be changed by
                  request.
                </strong>
              </p>
              <PassengerSummaryMessage />
              <Form.Checkbox
                field="agree"
                label="I confirm that all the details provided are correct. I understand that any changes to these details need to be requested and may incur an administration charge."
              />
            </React.Fragment>
          ) : (
            <p style={{ marginTop: "1em" }}>
              <strong>
                {submitValidationInvalid
                  ? `The following fields must be completed before submitting: ${submitValidationResult.errors.join(
                      ", "
                    )}`
                  : "You can save these passenger details now but all the missing information will need to be completed before confirming."}
              </strong>
            </p>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default SubmitDetails;
