import React from "react";
import moment from "moment";

const DisplayDateRange = ({ from, to, ...props }) => {
  const fromMoment = moment.utc(from);
  const toMoment = to ? moment.utc(to) : null;
  const dateFormat = "DD/MM/YYYY";

  if (!from) return null;

  return (
    <span {...props}>
      {toMoment && toMoment.diff(fromMoment, "days") != 0
        ? `${fromMoment.format(dateFormat)} - ${toMoment.format(dateFormat)}`
        : fromMoment.format(dateFormat)}
    </span>
  );
};

export default DisplayDateRange;
