import React from "react";
import { connect, useSelector } from "react-redux";
import {
  getAuthState,
  getAuthClaims,
  getAuthOptions,
  FormBuilder,
  Form,
  ErrorMessage,
  ScreenCheck,
  getResponsiveScreen,
} from "@redriver/cinnamon";
import { requestLogin } from "./actions";
import { Panel, Heading, BackgroundContent } from "components/containers";
import { RoundedButton } from "components/buttons";
import {
  ScreenBreakpoints,
  ScreenBreakpointNames,
} from "constants/breakpoints";
import classNames from "classnames";

const loginErrors = {
  915004: "Incorrect email address or password",
  915005: "Account is inactive",
};

const Login = ({ loggedIn, emailVerified, useEmailVerification }) => {
  const screen = useSelector(getResponsiveScreen);
  const isSmallScreen = screen?.screenSize == ScreenBreakpointNames.Small;

  if (loggedIn) {
    if (emailVerified || !useEmailVerification) {
      return <p>You have been successfully signed in</p>;
    }

    return (
      <div>
        <p>Your email address is not recognised</p>
      </div>
    );
  }

  const intro = (
    <div
      className={classNames({
        "mobile-header": isSmallScreen,
      })}
      style={{ marginBottom: "1em" }}
    >
      <Heading>Welcome to Select School Travel Passenger Portal</Heading>
      <p style={{ marginTop: 0 }}>
        Please login using the registration code you have received - Please
        note, each passenger will have a unique code for you to use
      </p>
    </div>
  );

  return (
    <div className="login">
      {isSmallScreen ? intro : null}
      <BackgroundContent />

      <Panel floating isBlue={isSmallScreen}>
        {!isSmallScreen ? intro : null}
        <FormBuilder
          submitAction={requestLogin}
          renderSubmitting={false}
          renderError={false}
          renderForm={(
            formProps,
            { submitting, slowSubmitting, error },
            events
          ) => (
            <Form {...formProps}>
              <Form.Email
                field="email"
                label="Email"
                placeholder="Enter email address"
                disabled={submitting && slowSubmitting}
                autoFocus
                required
                fluid
                icon="at"
              />
              <Form.Password
                field="password"
                label="Registration Code"
                placeholder="Enter code"
                disabled={submitting && slowSubmitting}
                required
                fluid
                toggleVisibility
              />

              <RoundedButton
                primary
                onClick={events.onSubmit}
                disabled={submitting}
                loading={submitting && slowSubmitting}
                fluid
              >
                Sign In
              </RoundedButton>
              <p>
                By signing in you are agreeing to our{" "}
                <a
                  href="https://selectschooltravel.com/about/gdpr-information"
                  target="_blank"
                >
                  GDPR policy
                </a>
              </p>
              {error && (
                <div className="login-error">
                  <ErrorMessage
                    header="Sign In Error"
                    error="Unable to sign in, please check your email and registration code are correct"
                  />
                </div>
              )}
            </Form>
          )}
        />
      </Panel>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const { useEmailVerification } = getAuthOptions();
  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
  };
};

export default connect(mapStateToProps)(Login);
