import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getAuthState, getResponsiveScreen } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { requestLogout } from "./actions";
import { BackgroundContent, Panel } from "components/containers";
import { RoundedButton } from "components/buttons";
import { ScreenBreakpointNames } from "constants/breakpoints";

const Logout = ({ loggedIn, requestLogout, children }) => {
  const [logoutStatus, setLogoutStatus] = useState(null);
  const screen = useSelector(getResponsiveScreen);
  const isSmallScreen = screen?.screenSize == ScreenBreakpointNames.Small;

  useEffect(() => {
    const logout = async () => {
      const logoutResult = await requestLogout();
      setLogoutStatus(logoutResult);
    };
    logout();
  }, []);

  return (
    <div>
      <BackgroundContent />
      <Panel floating isBlue={isSmallScreen}>
        {!loggedIn ? (
          <p>You have been successfully signed out</p>
        ) : logoutStatus ? (
          <p>Signing out...</p>
        ) : (
          <div>
            <p>Unable to sign out at this time</p>
            <div style={{ marginTop: 20 }}>
              <RoundedButton
                primary
                fluid
                onClick={() => setLogoutStatus(null)}
              >
                Try Again
              </RoundedButton>
            </div>
          </div>
        )}
        {children}
      </Panel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: getAuthState(state).loggedIn,
});

export default connect(mapStateToProps, { requestLogout })(Logout);
