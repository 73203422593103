const NAMESPACE = "OFFLINE_CHECKER";

export const ActionTypes = {
  SetOfflineStatus: `${NAMESPACE}/SET_OFFLINE_STATUS`,
};

export const setOfflineStatus = (isOffline) => ({
  type: ActionTypes.SetOfflineStatus,
  isOffline,
});
