import { Form, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button } from "semantic-ui-react";
import { sendQuestion } from "./actions";
import { ParentModal } from "components/containers/modals";

const SendQuestion = ({
  as: As = Button,
  onSubmitted = () => {},
  ...props
}) => (
  <ModalBuilder
    withForm
    submitAction={sendQuestion}
    onSubmitted={onSubmitted}
    renderTrigger={(openModal) => <As onClick={openModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <ParentModal {...modalProps} header="Send Question" size="small">
        <Form {...formProps}>
          <p style={{ marginTop: 0 }}>
            Please enter you enquiry/request in the field below. If you would
            like to be contacted by phone, please provide your telephone number.
          </p>
          <Form.Group widths={2}>
            <Form.Input field="phone" label="Phone" fluid />
          </Form.Group>
          <Form.TextArea field="question" label="My Question" fluid required />
        </Form>
      </ParentModal>
    )}
  />
);

export default SendQuestion;
