import { ActionTypes } from "./actions";

const initialState = {
  isOffline: navigator ? !navigator.onLine : false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SetOfflineStatus: {
      return {
        ...state,
        isOffline: !!action.isOffline,
      };
    }
    default:
      return state;
  }
};
