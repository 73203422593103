import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getPassengerFormData, getSteps } from "./selectors";
import * as yup from "yup";
import { PassengerSteps } from "constants/enums";

function usePassengerSubmitValidation(targetStepKey) {
  const steps = useSelector(getSteps);
  const formData = useSelector(getPassengerFormData);
  const [pending, setPending] = useState(false);
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState([]);

  const schema = useMemo(() => {
    const schemaShape = steps
      .filter((x) => !targetStepKey || x.key == targetStepKey)
      .reduce((acc, x) => {
        switch (x.key) {
          case PassengerSteps.Passenger:
            return {
              ...acc,
              [PassengerSteps.Passenger]: yup.object().shape({
                title: yup.string().nullable().required("Title"),
                firstName: yup.string().nullable().required("First Name"),
                lastName: yup.string().nullable().required("Last Name"),
                gender: yup.string().nullable().required("Gender"),
                dob: yup
                  .date()
                  .nullable()
                  .test("Date of Birth", "Date of Birth", (value) => {
                    return value != null && value < new Date();
                  }),
              }),
            };

          default:
            return acc;
        }
      }, {});
    return yup.object().shape(schemaShape);
  }, [steps]);

  useEffect(() => {
    setPending(true);

    Promise.allSettled([
      schema.isValid(formData),
      schema.validate(formData, { abortEarly: false }),
    ])
      .then(([validResult, errorsResult]) => {
        setValid(validResult.status == "fulfilled" ? validResult.value : false);
        setErrors(
          errorsResult.status == "rejected"
            ? errorsResult.reason?.errors || []
            : []
        );
      })
      .catch(() => {
        setValid(false);
        setErrors([]);
      })
      .finally(() => setPending(false));
  }, [schema, formData]);

  return { pending, valid, errors };
}

export default usePassengerSubmitValidation;
