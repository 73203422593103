import React from "react";
import classNames from "classnames";
import MainLogo from "assets/logo-main.svg";
import { useHistory } from "react-router-dom";
import { AppRoutes, SystemRoutes } from "constants/routes";
import {
  useLoggedIn,
  refreshJwt,
  getResponsiveScreen,
} from "@redriver/cinnamon";
import { Button, Image, Dimmer, Segment } from "semantic-ui-react";
import LogoutLogo from "assets/enter.svg";
import { OfflineNotice } from "components/banners";
import { useSelector } from "react-redux";

const PageWrapper = ({ className, children, ...props }) => {
  const history = useHistory();
  const isLoggedIn = useLoggedIn();
  const { screenSize } = useSelector(getResponsiveScreen);

  return (
    <article className={classNames("page-wrapper", className)} {...props}>
      <header>
        <div
          className={classNames("max", {
            "logged-in": isLoggedIn,
          })}
        >
          <img
            src={MainLogo}
            alt="Select School Travel"
            onClick={() => history.push(AppRoutes.Root)}
            style={{
              cursor: "pointer",
              height: screenSize == "small" ? 80 : "auto",
            }}
          />
          {isLoggedIn && (
            <div className="log-out">
              <Button
                primary
                compact
                size="mini"
                onClick={() => history.push(SystemRoutes.Logout)}
              >
                <Image src={LogoutLogo} />
              </Button>
            </div>
          )}
        </div>
      </header>
      <div className="page-content">
        <div className="max">{children}</div>
      </div>
      <footer>
        <div className="max">
          {/* <span className="footer-text">
            Select School Travel Limited, Second Floor, 30 Church Road, Burgess
            Hill, West Sussex, RH15 9AE
            <br />
            Company Reg. No.09388257. VAT No.208830611
            <br />© {new Date().getFullYear()} Select School Travel. All rights
            reserved.
          </span>
          <img
            src={MainLogo}
            alt="Select School Travel"
            className="footer-logo"
          /> */}
        </div>
      </footer>
      <OfflineNotice />
    </article>
  );
};

export default PageWrapper;
