import { apiGet, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "PARENT/DASHBOARD_INFO";

export const ActionTypes = {
  GetDashboardInfo: `${NAMESPACE}/GET_DASHBOARD_INFO`,
  SendQuestion: `${NAMESPACE}/SEND_QUESTION`,
  RequestCancellation: `${NAMESPACE}/REQUEST_CANCELLATION`,
};

export const getDashboardInfo = () => {
  return apiGet(
    ActionTypes.GetDashboardInfo,
    `passenger-portal/PassengerTripInfo`
  );
};

export const sendQuestion = (formData) => {
  const request = {
    ...formData,
  };

  return apiPost(
    ActionTypes.SendQuestion,
    `passenger-portal/SendQuestion`,
    request
  );
};

export const requestCancellation = (formData) => {
  const request = {
    ...formData,
  };
  return apiPost(
    ActionTypes.RequestCancellation,
    `passenger-portal/RequestCancellation`,
    request
  );
};
