export const AppRoutes = {
  Root: "/",
};

export const SystemRoutes = {
  Login: "/login",
  Logout: "/logout",
  ChildDetails: "/passenger-details",
  Dashboard: "/dashboard",
};

// example of matching sub-route parameters
//
// export const UserRoutes = {
//   User: (id = ":id") => `${AppRoutes.Users}/${id}`
// };
