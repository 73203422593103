import React from "react";
import moment from "moment";
import { Form, useLookup } from "@redriver/cinnamon";
import { useSelector } from "react-redux";
import { ColumnGrid } from "components/containers";
import { genderLookup } from "./lookups";
import { PassengerTypes } from "constants/enums";
import {
  getIsSubmitted,
  getCurrentStepLocked,
  passengerType,
} from "./selectors";
import DateOfBirthValidationMessage, {
  dateOfBirthInvalid,
} from "./DateOfBirthValidationMessage";
import TabLockedMessage from "./TabLockedMessage";
import { titlesLookup } from "./lookups";

const PassengerForm = () => {
  const isSubmitted = useSelector(getIsSubmitted);
  const isLocked = useSelector(getCurrentStepLocked);

  const pType = useSelector(passengerType);
  const [{ loading: gendersLoading, response: gendersResponse }] =
    useLookup(genderLookup);

  const genders = (gendersResponse || []).map((x) => {
    return {
      ...x,
      disabled:
        x.value == "Other" && pType.passengerType != PassengerTypes.Adult,
    };
  });
  return (
    <ColumnGrid cols={2}>
      <ColumnGrid.Item>
        <Form.Dropdown
          field="title"
          textField="title"
          label="Title"
          fluid
          required={pType.passengerType == PassengerTypes.Adult}
          disabled={
            isSubmitted ||
            isLocked ||
            pType.passengerType != PassengerTypes.Adult
          }
          lookup={titlesLookup}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Input
          field="firstName"
          label="First Name"
          fluid
          required
          disabled={isSubmitted || isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Input
          field="middleName"
          label="Middle Name"
          fluid
          disabled={isSubmitted || isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Input
          field="lastName"
          label="Last Name"
          fluid
          required
          disabled={isSubmitted || isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Dropdown
          field="gender"
          label="Gender"
          fluid
          placeholder="Please select"
          required
          clearable
          disabled={isSubmitted || isLocked}
          loading={gendersLoading}
          options={genders}
        />
        {pType.passengerType != PassengerTypes.Adult && (
          <Form.Trigger
            field="gender"
            target="title"
            action="change"
            value={({ fields }) => {
              switch (fields.gender) {
                case "Male":
                  return "Mr";
                case "Female":
                  return "Miss";
                default:
                  return "";
              }
            }}
          />
        )}
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Date
          field="dob"
          label="Date of Birth"
          fluid
          local
          required
          disabled={isSubmitted || isLocked}
          showMonthDropdown
          showYearDropdown
          maxDate={new moment()}
        />
      </ColumnGrid.Item>
      {dateOfBirthInvalid && (
        <ColumnGrid.Item colsWidth={2}>
          <DateOfBirthValidationMessage />
        </ColumnGrid.Item>
      )}
      {isLocked && (
        <ColumnGrid.Item colsWidth={2}>
          <TabLockedMessage />
        </ColumnGrid.Item>
      )}
    </ColumnGrid>
  );
};

export default PassengerForm;
