import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  getAuthState,
  getAuthClaims,
  getAuthOptions,
  PageContent,
  ScreenCheck,
} from "@redriver/cinnamon";
import { Login } from "features/System";
import { AppRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";
import classNames from "classnames";
import MainLogo from "assets/logo-main.svg";
import SimpleMountains from "assets/simple-mountains.svg";
import { PageWrapper } from "components/pages";

const LoginPage = ({
  loggedIn,
  emailVerified,
  useEmailVerification,
  location,
}) => {
  if (loggedIn && (emailVerified || !useEmailVerification)) {
    const nextRoute =
      location.state && location.state.nextPath
        ? location.state.nextPath
        : AppRoutes.Root;
    return <Redirect to={nextRoute} />;
  }

  return (
    <PageWrapper>
      <Login />
    </PageWrapper>
  );
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const { useEmailVerification } = getAuthOptions();
  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
  };
};

export default connect(mapStateToProps)(LoginPage);
