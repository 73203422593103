import React from "react";
import { useSelector } from "react-redux";
import { Form, useLookup } from "@redriver/cinnamon";
import { ColumnGrid } from "components/containers";
import {
  skiAbilitiesLookup,
  skierTypesLookup,
  ownSkiEquipmentLookup,
} from "./lookups";
import {
  SkiAbilityExtraInfoMap,
  SkierTypes,
  PassengerTypes,
  OwnSkiEquipment,
} from "constants/enums";
import {
  passengerType,
  getNoSnowboarders,
  getCurrentStepLocked,
} from "./selectors";
import TabLockedMessage from "./TabLockedMessage";

const SkiForm = () => {
  const [{ loading, response }] = useLookup(skiAbilitiesLookup);
  const isLocked = useSelector(getCurrentStepLocked);

  const abilities = (response || []).map((x) => ({
    value: x.value,
    text: SkiAbilityExtraInfoMap[x.value]
      ? `${x.text}: ${SkiAbilityExtraInfoMap[x.value]}`
      : x.text,
  }));

  const [{ loading: skierTypesLoading, response: skierTypeResponse }] =
    useLookup(skierTypesLookup);
  const pType = useSelector(passengerType);
  const noSnowboarders = useSelector(getNoSnowboarders);

  const skierTypes = (skierTypeResponse || []).map((x) => {
    return {
      ...x,
      disabled:
        (x.value == SkierTypes.NonSkier &&
          pType.passengerType != PassengerTypes.Adult) ||
        (noSnowboarders && x.value == SkierTypes.Snowboarder),
    };
  });

  const [{ loading: ownEquipmentLoading, response: ownEquipmentResponse }] =
    useLookup(ownSkiEquipmentLookup);

  const ownEquipment = (ownEquipmentResponse || []).map((x) => {
    return {
      ...x,
      disabled:
        noSnowboarders &&
        (x.value == OwnSkiEquipment.OwnSnowboardBoots ||
          x.value == OwnSkiEquipment.OwnSnowboard ||
          x.value == OwnSkiEquipment.OwnSnowboardAndBoots),
    };
  });

  return (
    <ColumnGrid cols={2}>
      <ColumnGrid.Item>
        <Form.Dropdown
          field="skierType"
          label="Skier/Boarder"
          options={skierTypes}
          loading={skierTypesLoading}
          fluid
          clearable
          required
          disabled={isLocked}
        />
        <Form.If
          condition={({ fields }) => fields.skierType == SkierTypes.Snowboarder}
        >
          <p>
            Some groups are unable to offer snowboarding as an option, and if it
            is available will often incur a supplement. If you select
            snowboarding, this will be subject to confirmation by the group
            leader.
          </p>
        </Form.If>
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Dropdown
          field="skiAbility"
          label="Ability"
          options={abilities}
          loading={loading}
          fluid
          clearable
          required
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Dropdown
          field="ownEquipment"
          label="Own Equipment?"
          options={ownEquipment}
          loading={ownEquipmentLoading}
          fluid
          clearable
          maxWidth
          disabled={isLocked}
        />
        <p>
          Own Ski/Board Equipment (Rental of skis, boots, poles and helmets is
          included. Leave this section blank unless you are planning to take
          your own skis / snowboard / boots. Please note that supplements may
          apply for carriage of your own equipment).
        </p>
      </ColumnGrid.Item>
      {/* <ColumnGrid.Item>
        <YesNoRadioField field="helmet" label="Helmet Required?" />
      </ColumnGrid.Item> */}
      <ColumnGrid.Item>
        <Form.Numeric
          field="height"
          label="Height (cm)"
          fluid
          decimalPlaces={0}
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Numeric
          field="weight"
          label="Weight (kg)"
          fluid
          decimalPlaces={0}
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Input
          field="shoeSize"
          label="UK Shoe Size"
          fluid
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Numeric
          field="headSize"
          label="Head Circumference (cm)"
          fluid
          decimalPlaces={0}
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      {isLocked && (
        <ColumnGrid.Item colsWidth={2}>
          <TabLockedMessage />
        </ColumnGrid.Item>
      )}
    </ColumnGrid>
  );
};

export default SkiForm;
