import React, { useMemo } from "react";
import { Form, useLookup } from "@redriver/cinnamon";
import { ColumnGrid } from "components/containers";
import { countriesLookup } from "./lookups";
import PassportValidationMessage, {
  passportDateInvalid,
  passportExpiresCloseToReturn,
} from "./PassportValidationMessage";
import { useSelector } from "react-redux";
import { getCurrentStepLocked } from "./selectors";
import TabLockedMessage from "./TabLockedMessage";

const PassportForm = () => {
  const [{ loading, response }] = useLookup(countriesLookup);
  const hasInvalidPassport = passportDateInvalid();
  const hasExpiringPassport = passportExpiresCloseToReturn();
  const countryOptions = loading ? [] : response || [];
  const countryCodeOptions = useMemo(
    () =>
      countryOptions.map((x) => ({
        ...x,
        text: `${x.text} (${x.code})`,
      })),
    [countryOptions]
  );
  const isLocked = useSelector(getCurrentStepLocked);

  return (
    <ColumnGrid cols={2}>
      <ColumnGrid.Item>
        <Form.Input
          field="passportNum"
          label="Passport Number"
          fluid
          required
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Date
          field="passportDateOfIssue"
          label="Passport Date of Issue"
          fluid
          local
          required
          showMonthDropdown
          showYearDropdown
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.Date
          field="passportExpiryDate"
          label="Passport Expiry Date"
          fluid
          local
          required
          showMonthDropdown
          showYearDropdown
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.TypeAhead
          field="nationality"
          label="Nationality"
          options={countryOptions}
          loading={loading}
          fluid
          clearable
          required
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      <ColumnGrid.Item>
        <Form.TypeAhead
          field="issueCountry"
          label="Country of Issue/Code"
          options={countryCodeOptions}
          loading={loading}
          fluid
          clearable
          required
          disabled={isLocked}
        />
      </ColumnGrid.Item>
      {(hasInvalidPassport || hasExpiringPassport) && (
        <ColumnGrid.Item colsWidth={2}>
          <PassportValidationMessage />
        </ColumnGrid.Item>
      )}
      {isLocked && (
        <ColumnGrid.Item colsWidth={2}>
          <TabLockedMessage />
        </ColumnGrid.Item>
      )}
    </ColumnGrid>
  );
};

export default PassportForm;
