import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getPassengerFormData, getSteps } from "./selectors";
import * as yup from "yup";
import { PassengerSteps } from "constants/enums";

function usePassengerValidation(targetStepKey) {
  const steps = useSelector(getSteps);
  const formData = useSelector(getPassengerFormData);
  const [pending, setPending] = useState(false);
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState([]);

  const schema = useMemo(() => {
    const schemaShape = steps
      .filter((x) => !targetStepKey || x.key == targetStepKey)
      .reduce((acc, x) => {
        switch (x.key) {
          case PassengerSteps.Passenger:
            return {
              ...acc,
              [PassengerSteps.Passenger]: yup.object().shape({
                title: yup.string().nullable().required("Title"),
                firstName: yup.string().nullable().required("First Name"),
                lastName: yup.string().nullable().required("Last Name"),
                gender: yup.string().nullable().required("Gender"),
                dob: yup
                  .date()
                  .nullable()
                  .test("Date of Birth", "Date of Birth", (value) => {
                    return value != null && value < new Date();
                  }),
              }),
            };
          case PassengerSteps.Passport:
            return {
              ...acc,
              [PassengerSteps.Passport]: yup
                .object()
                .nullable()
                .shape({
                  passportNum: yup
                    .string()
                    .nullable()
                    .required("Passport Number"),
                  passportDateOfIssue: yup
                    .string()
                    .nullable()
                    .required("Passport Date of Issue"),
                  passportExpiryDate: yup
                    .string()
                    .nullable()
                    .required("Passport Expiry Date"),
                  nationality: yup.string().nullable().required("Nationality"),
                  issueCountry: yup
                    .string()
                    .nullable()
                    .required("Country of Issue/Code"),
                }),
            };
          case PassengerSteps.Medical:
            return {
              ...acc,
              [PassengerSteps.Medical]: yup
                .object()
                .nullable()
                .shape({
                  hasMedicalRequirements: yup
                    .bool()
                    .nullable()
                    .required(
                      "Does the passenger have medical/dietary requirements?"
                    ),
                  epipenCarrier: yup
                    .bool()
                    .nullable()
                    .when("hasMedicalRequirements", {
                      is: (x) => x == true,
                      then: yup
                        .bool()
                        .nullable()
                        .required("Does the passenger carry an epipen?"),
                    }),
                }),
            };
          case PassengerSteps.Ski:
            return {
              ...acc,
              [PassengerSteps.Ski]: yup
                .object()
                .nullable()
                .shape({
                  skierType: yup.string().nullable().required("Skier Type"),
                  skiAbility: yup.string().nullable().required("Ski Ability"),
                  height: yup.string().nullable().required("Height"),
                  weight: yup.string().nullable().required("Weight"),
                  shoeSize: yup.string().nullable().required("Shoe Size"),
                  headSize: yup
                    .string()
                    .nullable()
                    .required("Head Circumference"),
                }),
            };

          default:
            return acc;
        }
      }, {});
    return yup.object().shape(schemaShape);
  }, [steps]);

  useEffect(() => {
    setPending(true);

    Promise.allSettled([
      schema.isValid(formData),
      schema.validate(formData, { abortEarly: false }),
    ])
      .then(([validResult, errorsResult]) => {
        setValid(validResult.status == "fulfilled" ? validResult.value : false);
        setErrors(
          errorsResult.status == "rejected"
            ? errorsResult.reason?.errors || []
            : []
        );
      })
      .catch(() => {
        setValid(false);
        setErrors([]);
      })
      .finally(() => setPending(false));
  }, [schema, formData]);

  return { pending, valid, errors };
}

export default usePassengerValidation;
