import React from "react";
import { useSelector } from "react-redux";
import { getCurrentStepLocked } from "./selectors";

const TabLockedMessage = () => {
  const isLocked = useSelector(getCurrentStepLocked);

  return isLocked ? (
    <p className="step-validation-message">
      This information can no longer be changed - please contact your Party
      Leader
    </p>
  ) : null;
};

export default TabLockedMessage;
