import React from "react";
import classNames from "classnames";
import { getResponsiveScreen } from "@redriver/cinnamon";
import { useSelector } from "react-redux";
import { Panel } from "components/containers";
import { ScreenBreakpointNames } from "constants/breakpoints";

const FormPanel = ({ children, className, ...props }) => {
  const screen = useSelector(getResponsiveScreen);
  const isSmallScreen = screen?.screenSize == ScreenBreakpointNames.Small;

  if (isSmallScreen) {
    return (
      <Panel
        className={className}
        isBlue
        style={{ boxShadow: "none" }}
        {...props}
      >
        {children}
      </Panel>
    );
  }

  return (
    <div className={classNames("form-panel", className)} {...props}>
      {children}
    </div>
  );
};

export default FormPanel;
