import {
  ActionBar,
  getResponsiveScreen,
  PageLoader,
  useApiAction,
  notifySuccess,
} from "@redriver/cinnamon";
import { BackgroundContent, Heading, Panel } from "components/containers";
import { ScreenBreakpointNames } from "constants/breakpoints";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardInfo } from "./actions";
import { DisplayDateRange } from "components/display";
import { Button, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { SystemRoutes } from "constants/routes";
import { PassengerPortalStatus } from "constants/enums";
import SendQuestion from "./SendQuestion";
import RequestCancellation from "./RequestCancellation";

const Dashboard = () => {
  const screen = useSelector(getResponsiveScreen);
  const isSmallScreen = screen?.screenSize == ScreenBreakpointNames.Small;
  const [{ busy, response }] = useApiAction(getDashboardInfo, { lazy: false });
  const history = useHistory();

  const dispatch = useDispatch();

  return (
    <div>
      <BackgroundContent />
      <Panel floating isBlue={isSmallScreen}>
        <Heading className="trip-details-header">Trip Details</Heading>
        {busy ? (
          <PageLoader />
        ) : (
          <div>
            <p>
              <strong>{`${response?.passengerFirstName} ${response?.passengerLastName}`}</strong>
            </p>
            <p>{response?.customerName || "-"}</p>
            {response?.destinationName && (
              <p>
                <strong>{response?.destinationName || "-"}</strong>
              </p>
            )}
            <p>
              <strong>
                <DisplayDateRange
                  from={response?.departureDate}
                  //to={response?.returnDate}
                />
              </strong>
            </p>
            <p>{buildStatusMessage(response?.status)}</p>

            <ActionBar className="passenger-actions">
              <ActionBar.Item>
                <Button
                  onClick={() => history.push(SystemRoutes.ChildDetails)}
                  primary
                  disabled={response?.status == PassengerPortalStatus.Cancelled}
                >
                  {response?.status == PassengerPortalStatus.Completed
                    ? "View"
                    : "Enter"}{" "}
                  Passenger Details
                </Button>
              </ActionBar.Item>
              <ActionBar.Item>
                <SendQuestion
                  as={Button}
                  primary
                  content="Send Question"
                  onSubmitted={() =>
                    dispatch(
                      notifySuccess(
                        "Thank you for submitting your enquiry, the Group Organiser will contact you shortly"
                      )
                    )
                  }
                />
              </ActionBar.Item>
              <ActionBar.Item>
                <RequestCancellation
                  as={Button}
                  primary
                  content="Request Cancellation"
                  onSubmitted={() =>
                    dispatch(
                      notifySuccess(
                        "Thank you for submitting your request, the Group Organiser will contact you shortly"
                      )
                    )
                  }
                />
              </ActionBar.Item>
              <ActionBar.Item>
                <Button
                  primary
                  content="Download Entry Requirements Information"
                  onClick={() => {
                    window.open(`/public/requirements.pdf`, "_blank");
                  }}
                />
              </ActionBar.Item>
            </ActionBar>
          </div>
        )}
      </Panel>
    </div>
  );
};

const buildStatusMessage = (status) => {
  if (status == PassengerPortalStatus.Completed)
    return (
      <em>
        <Icon name="check" color="green" />
        You have filled out and confirmed all required passenger details
      </em>
    );
  else if (status == PassengerPortalStatus.Submitted)
    return (
      <em>
        <Icon name="exclamation" color="blue" />
        Please fill out the remaining required passenger details and confirm
      </em>
    );
  else if (status == PassengerPortalStatus.Cancelled)
    return (
      <em>
        <Icon name="cancel" color="red" />
        The passenger's place has been cancelled
      </em>
    );
  else return <span>Please fill out the passenger's details</span>;
};

export default Dashboard;
