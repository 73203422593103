import React, { useContext } from "react";
import classNames from "classnames";
import TickIcon from "assets/tick.svg";
import {
  getCompletedStepIndex,
  getCurrentStepKey,
  getIsConfirmed,
  getIsSubmitted,
} from "./selectors";
import { useDispatch, useSelector } from "react-redux";
import usePassengerValidation from "./usePassengerValidation";
import { PassengerSteps } from "constants/enums";
import { moveStep, saveStep } from "./actions";

const ProgressSteps = ({ steps }) => {
  const completedStepIndex = useSelector(getCompletedStepIndex);
  const completedStepsCount =
    completedStepIndex != null && completedStepIndex >= 0
      ? completedStepIndex + 1
      : 0;

  return (
    <ol className="progress-steps">
      {steps.map((x, i) => (
        <ProgressStep
          key={i}
          index={i}
          step={x}
          completedStepsCount={completedStepsCount}
        />
      ))}
    </ol>
  );
};

const ProgressStep = ({ index, step, completedStepsCount, onGoToStep }) => {
  const { valid } = usePassengerValidation(step.key);
  const wholeForm = usePassengerValidation();
  const isSubmitted = useSelector(getIsSubmitted);
  const isConfirmed = useSelector(getIsConfirmed);
  const completedStepIndex = useSelector(getCompletedStepIndex);
  const currentStepKey = useSelector(getCurrentStepKey);
  const isSubmitStep = step.key == PassengerSteps.Submit;

  const isStepComplete = isSubmitStep
    ? wholeForm.valid && isConfirmed
    : index < completedStepsCount && valid;

  const dispatch = useDispatch();

  return (
    <li
      className={classNames("step", { "can-nav": isSubmitted })}
      onClick={() => {
        if (isSubmitted) {
          if (!isConfirmed) {
            dispatch(
              saveStep({
                stepKey: currentStepKey,
                completedStepIndex,
              })
            );
          }
          dispatch(moveStep({ targetStepKey: step.key }));
        }
      }}
    >
      <div
        className={classNames("step-circle", {
          complete: index < completedStepsCount,
        })}
      >
        {isStepComplete ? <img src={TickIcon} /> : null}
      </div>
      <span className="step-name">{step.name}</span>
    </li>
  );
};

export default ProgressSteps;
