import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import { Wizard, Form, PageLoader } from "@redriver/cinnamon";
import { getPassenger, updateForm } from "./actions";
import {
  getPassengerLoading,
  getCurrentStepKey,
  getSteps,
  getPassengerFormData,
  getIsSubmitted,
  getIsConfirmed,
} from "./selectors";
import { useDispatch, useSelector } from "react-redux";
import ProgressSteps from "./ProgressSteps";
import FormStep from "./FormStep";

const EditChildDetails = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getPassengerLoading);
  const currentStepKey = useSelector(getCurrentStepKey);
  const steps = useSelector(getSteps);
  const formData = useSelector(getPassengerFormData);
  const isSubmitted = useSelector(getIsSubmitted);
  const isConfirmed = useSelector(getIsConfirmed);

  useEffect(() => {
    dispatch(getPassenger());
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="child-details">
      <Form
        value={formData}
        onChange={(field, data, applyChanges) =>
          dispatch(updateForm({ applyChanges }))
        }
        disabled={isConfirmed}
      >
        <Wizard
          steps={steps}
          step={currentStepKey != null ? currentStepKey : steps[0].key}
        >
          <Wizard.Nav component={ProgressSteps} />
          {steps.map((x) => (
            <Wizard.Step
              key={x.key}
              step={x.key}
              render={(stepProps) => <FormStep {...stepProps} steps={steps} />}
            />
          ))}
        </Wizard>
      </Form>
    </div>
  );
};

export default EditChildDetails;
