export const TripTypes = {
  Ski: "Ski",
  SkiInspection: "SkiInspection",
  EducationalTour: "EducationalTour",
  EducationalTourInspection: "EducationalTourInspection",
};

export const PassengerSteps = {
  Passenger: "passenger",
  Passport: "passport",
  Medical: "medical",
  Ski: "ski",
  Submit: "submit",
};

// must be in sync with the c# enum of the same name
export const PassengerPortalStatus = {
  Incomplete: "Incomplete",
  Completed: "Completed",
  Cancelled: "Cancelled",
  Submitted: "Submitted",
};

export const enumOptionsArray = (enumObj) => {
  return Object.keys(enumObj).map((k) => ({ value: k, text: enumObj[k] }));
};

export const SkierTypes = {
  NonSkier: "NonSkier",
  Snowboarder: "Snowboarder",
  Skier: "Skier",
};

export const SkiAbilityExtraInfoMap = {
  AbsoluteBeginner:
    "First time: may have had an introductory session to the sport",
  LowerIntermediate:
    "Usually 1-2 weeks experience with the ability to link turns on beginner terrain",
  HigherIntermediate:
    "Linking turns and ability to control speed on more blue and red runs",
  AdvancedSkierOrRider:
    "Confident getting down any marked pistes on the mountain",
};

export const PassengerTypes = {
  Adult: "Adult",
  Pupil: "Pupil",
  Infant: "Infant",
};

export const OwnSkiEquipment = {
  OwnSkiBoots: "OwnSkiBoots",
  OwnSkis: "OwnSkis",
  OwnSkisAndBoots: "OwnSkisAndBoots",
  OwnSnowboardBoots: "OwnSnowboardBoots",
  OwnSnowboard: "OwnSnowboard",
  OwnSnowboardAndBoots: "OwnSnowboardAndBoots",
  NonSkier: "NonSkier",
};

export const PassengerTabs = {
  KeyInfo: "KeyInfo",
  Apis: "Apis",
  Ski: "Ski",
  Diet: "Diet",
  Additional: "Additional",
};
