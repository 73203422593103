import React from "react";
import { registerLookup } from "@redriver/cinnamon";
import {
  getCountries,
  getSkiAbilities,
  getGenderOptions,
  getSkier,
  getOwnSkiEquipment,
  getTitles,
} from "./actions";

export const countriesLookup = registerLookup("countriesLookup", getCountries, {
  transform: (data) => {
    const uk = data.find((x) => x.code == "GBR");
    return [uk, ...data.filter((x) => x.code != "GBR")].map((x) => ({
      ...x,
      value: parseInt(x.value),
    }));
  },
});
export const skiAbilitiesLookup = registerLookup(
  "skiAbilitiesLookup",
  getSkiAbilities
);
export const genderLookup = registerLookup("genderLookup", getGenderOptions);
export const skierTypesLookup = registerLookup("skierTypesLookup", getSkier);
export const ownSkiEquipmentLookup = registerLookup(
  "ownSkiEquipmentLookup",
  getOwnSkiEquipment
);

export const titlesLookup = registerLookup("titlesLookup", getTitles);
