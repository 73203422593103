export const ScreenBreakpoints = {
  SmallMax: 899,
  MediumMin: 900,
  MediumMax: 1199,
  LargeMin: 1200,
};

export const ScreenBreakpointNames = {
  Small: "small",
  Medium: "medium",
  Large: "large",
};
