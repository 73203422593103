import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getPassengerFormData, getReturnDate } from "./selectors";

export const passportDateInvalid = () => {
  const passengerFormData = useSelector(getPassengerFormData);
  const returnDate = useSelector(getReturnDate);

  return (
    !!passengerFormData?.passport?.passportExpiryDate &&
    moment(passengerFormData.passport.passportExpiryDate) < moment(returnDate)
  );
};

export const passportExpiresCloseToReturn = () => {
  const passengerFormData = useSelector(getPassengerFormData);
  const returnDate = useSelector(getReturnDate);
  return (
    !!passengerFormData?.passport?.passportExpiryDate &&
    moment(passengerFormData.passport.passportExpiryDate).diff(
      returnDate,
      "months"
    ) < 6
  );
};

const PassportValidationMessage = () => {
  const isInvalid = passportDateInvalid();
  const expiresSoon = passportExpiresCloseToReturn();

  return isInvalid || expiresSoon ? (
    <p className="step-validation-message">
      {isInvalid
        ? "Your passport is not valid for dates of travel please check and/or renew"
        : "Your passport is due to expire within 6 months of your return date. Please check entry requirements for your destination as you may need to renew your passport."}
    </p>
  ) : null;
};

export default PassportValidationMessage;
