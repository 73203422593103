import { Form } from "@redriver/cinnamon";
import classNames from "classnames";
import React from "react";

const CascadingRadioGroup = ({ className, ...props }) => (
  <div className={classNames("cascading-radio-group field", className)}>
    <Form.RadioGroup {...props} />
  </div>
);

export default CascadingRadioGroup;
