import React from "react";
import { Modal } from "@redriver/cinnamon";
import classNames from "classnames";

const ParentModal = ({ className, children, ...props }) => (
  <Modal
    className={classNames("parent-modal", className)}
    submitIcon={null}
    cancelIcon={null}
    {...props}
  >
    {children}
  </Modal>
);

export default ParentModal;
