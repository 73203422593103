import React from "react";
import { submitDetails } from "./actions";
import {
  hasAgreedToSubmission,
  getIsSubmitting,
  getIsValid,
  getIsSubmitted,
  getIsSaving,
} from "./selectors";
import { useDispatch, useSelector } from "react-redux";
import { RoundedButton } from "components/buttons";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import { ActionBar, notifySuccess } from "@redriver/cinnamon";
import usePassengerSubmitValidation from "./usePassengerSubmitValidation";

const SubmitButton = ({ confirm }) => {
  const hasAgreed = useSelector(hasAgreedToSubmission);
  const isSubmitting = useSelector(getIsSubmitting);
  const isSaving = useSelector(getIsSaving);
  const isSubmitted = useSelector(getIsSubmitted);
  const isValid = useSelector(getIsValid);
  const dispatch = useDispatch();
  const history = useHistory();
  const submitValidationResult = usePassengerSubmitValidation(null);
  const submitValidationInvalid =
    !submitValidationResult.pending && !submitValidationResult.valid;

  return confirm && !isValid ? null : (
    <ActionBar.Item>
      <RoundedButton
        positive
        loading={confirm ? isSubmitting : isSaving}
        onClick={() => {
          dispatch(submitDetails(confirm, true)).then(() => {
            dispatch(
              notifySuccess(
                `The passenger details have been saved${
                  confirm ? " and confirmed" : ""
                }`
              )
            );
            history.push(AppRoutes.Root);
          });
        }}
        disabled={
          (!isSubmitted && submitValidationInvalid) ||
          (confirm ? isSaving || !hasAgreed : isSubmitting)
        }
      >
        {confirm ? "Confirm" : "Save"}
      </RoundedButton>
    </ActionBar.Item>
  );
};

export default SubmitButton;
