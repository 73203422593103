import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { NavSidebar, NotificationStack, NotFound } from "@redriver/cinnamon";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { ChildDetailsPage, ParentDashboardPage } from "pages/parents";

const ParentPortal = () => (
  <React.Fragment>
    <Switch>
      <Route
        exact
        path={SystemRoutes.Dashboard}
        component={ParentDashboardPage}
      />
      <Route
        exact
        path={SystemRoutes.ChildDetails}
        component={ChildDetailsPage}
      />
      <Route exact path={AppRoutes.Root} component={ParentDashboardPage} />

      <Route component={NotFound} />
    </Switch>
    <NotificationStack />
  </React.Fragment>
);

export default ParentPortal;
