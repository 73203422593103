import classNames from "classnames";
import React from "react";
import { Segment } from "semantic-ui-react";

const Panel = ({ className, children, isBlue, floating, ...props }) => {
  return (
    <Segment
      className={classNames(
        "panel",
        {
          blue: isBlue,
          "floating-panel": floating,
        },
        className
      )}
      {...props}
    >
      {children}
    </Segment>
  );
};

export default Panel;
