import classNames from "classnames";
import React from "react";
import CascadingRadioGroup from "./CascadingRadioGroup";

const yesNoOptions = [
  { value: true, text: "Yes" },
  { value: false, text: "No" },
];

const YesNoRadioField = ({ className, ...props }) => {
  return (
    <CascadingRadioGroup
      {...props}
      className={classNames("yes-no-radio", className)}
      options={yesNoOptions}
    />
  );
};

export default YesNoRadioField;
