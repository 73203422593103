import { PassengerSteps } from "constants/enums";

export const getPassengerFormData = (state) => state.passenger.formData;
export const getPassengerLoading = (state) => state.passenger.loading;
export const getTripType = (state) => state.passenger.tripType;
export const getIsUkTrip = (state) => state.passenger.isUkTrip;
export const getNoSnowboarders = (state) => state.passenger.noSnowboarders;
export const getReturnDate = (state) => state.passenger.returnDate;
export const getCompletedStepIndex = (state) =>
  state.passenger.completedStepIndex;
export const getCurrentStepKey = (state) => state.passenger.currentStepKey;
export const getSteps = (state) => state.passenger.steps;
export const getCurrentStepLocked = (state) =>
  state.passenger.steps.find((x) => x.key === state.passenger.currentStepKey)
    ?.locked;
export const getIsSubmitted = (state) => state.passenger.isSubmitted;
export const getIsConfirmed = (state) => state.passenger.isConfirmed;
export const hasAgreedToSubmission = (state) => {
  const formData = getPassengerFormData(state);
  return !!formData[PassengerSteps.Submit]?.agree;
};
export const getIsSubmitting = (state) => state.passenger.submitting;
export const getIsSaving = (state) => state.passenger.saving;
export const getIsValid = (state) => state.passenger.isValid;
export const passengerType = (state) => state.passenger;
