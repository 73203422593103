import React from "react";
import classNames from "classnames";
import { Grid, Image } from "semantic-ui-react";
import Logo from "assets/logo.png";

const SystemPageContent = ({ title, className, children }) => (
  <div className={classNames("system-page-content", className)}>
    <Grid>
      <Grid.Column computer={6} mobile={16}>
        <div className="content">
          <Image src={Logo} alt="Passenger Portal" centered className="logo" />
          {title && <h3>{title}</h3>}
          {children}
        </div>
      </Grid.Column>
      <Grid.Column computer={10} className="background" only="computer" />
    </Grid>
  </div>
);

export default SystemPageContent;
