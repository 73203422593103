import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { getAuthState } from "@redriver/cinnamon";
import { Logout } from "features/System";
import { AppRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";
import { PageWrapper } from "components/pages";
import { Heading } from "components/containers";
import { RoundedButton } from "components/buttons";

const LogoutPage = ({ loggedIn, location }) => {
  const nextRoute =
    location.state && location.state.nextPath
      ? location.state.nextPath
      : AppRoutes.Root;

  return (
    <PageWrapper>
      <Logout>
        {!loggedIn && (
          <div style={{ marginTop: 20 }}>
            <RoundedButton primary fluid as={Link} to={nextRoute}>
              Continue
            </RoundedButton>
          </div>
        )}
      </Logout>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: getAuthState(state).loggedIn,
});

export default connect(mapStateToProps)(LogoutPage);
