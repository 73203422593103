import React, { useContext } from "react";
import { useResponsive } from "@redriver/cinnamon";
import classNames from "classnames";

const ColumnGridContext = React.createContext({ cols: 1 });

const ColumnGrid = React.forwardRef(
  (
    { cols = 1, gap = "1em 4em", className, children, style, ...props },
    ref
  ) => (
    <ColumnGridContext.Provider
      value={{
        cols: cols || 1,
      }}
    >
      <div
        className={classNames("column-grid", className)}
        style={{
          ...(style || {}),
          gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
          gridGap: gap,
        }}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    </ColumnGridContext.Provider>
  )
);

// grid item which will span varying columns width
ColumnGrid.Item = ({
  isFullWidth,
  colsWidth,
  className,
  style,
  children,
  ...props
}) => {
  const { cols } = useContext(ColumnGridContext);
  const { screenSize } = useResponsive();
  const spanCols =
    isFullWidth || screenSize == "small" || colsWidth > cols
      ? cols
      : colsWidth || 1;
  return (
    <div
      className={classNames("column-grid-item", className)}
      style={{ ...(style || {}), gridColumn: `auto / span ${spanCols}` }}
      {...props}
    >
      {children}
    </div>
  );
};

export default ColumnGrid;
