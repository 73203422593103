import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { setupApp } from "./setup";
import Shell from "./pages/Shell";

const { store } = setupApp();

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Shell />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root"));

// setup service worker for PWA
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js").then(
    function (registration) {
      console.log("Service worker registration succeeded:", registration);
    },
    /*catch*/ function (error) {
      console.log("Service worker registration failed:", error);
    }
  );
  if (window.matchMedia("(display-mode: standalone)").matches) {
    document.title = "";
  }
}
