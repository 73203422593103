import React from "react";
import { PageWrapper } from "components/pages";
import { Dashboard } from "features/Parent/Dashboard";

const ParentDashboardPage = () => {
  return (
    <PageWrapper>
      <Dashboard />
    </PageWrapper>
  );
};

export default ParentDashboardPage;
